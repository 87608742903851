<template>
  <div class="introduce">
    <div id="demo"></div>
    <div class="btn">
      <div class="agree-box">
        <van-checkbox v-model="checked" :disabled="checkDisabled" icon-size="16px">我已阅读并同意该知情同意书</van-checkbox>
      </div>
      <van-button round type="info" size="large" class="up" :disabled="checked ? btnDisabled : true" @click="next">
        {{ this.skipSign ? btn_text : btn_text == '下一步' ? btn_text : '点击签名' }}
      </van-button>
    </div>
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
import { getSubProjectAgreement, projectDoctor, StepFour, getDoctorForm } from '@/api/api'
import { mapActions, mapGetters, mapState } from 'vuex'
import { appHost } from '@/utils/host'
import { parseUrl } from '@/utils/utils'
import { Toast, Dialog } from 'vant'
export default {
  data() {
    return {
      project_doctor_id: null,
      baseFileUrl: appHost,
      pdfh5: null,
      checked: false,
      skipSign: false,
      checkDisabled: true,
      btnDisabled: false,
      formList: [],
      short_name: '', //判断项目
      btn_text: '',
      is_bankcard_pay: false
    }
  },
  mounted() {
    this.short_name = this.basicInformation.projects[0].short_name
    this.project_doctor_id = this.$route.query.project_doctor_id
    this.init()
  },
  computed: {
    ...mapGetters(['project_id', 'sub_id']),
    ...mapState(['imgList', 'idcardList', 'userId', 'basicInformation']) //获取身份证
  },
  methods: {
    async init() {
      if (this.basicInformation?.subprojects[0]?.bind_type == 1) {
        // if (["nyfzeq", "bxzleq", "axb", "ryrs", "xhzl", "sshy", "axbplus"].includes(this.short_name)) {
        //诺亚方舟二期，按钮文字显示为下一步 && 靶向治疗二期 && 瑞意人生 && 安欣保（安心首选）&& 消化肿瘤2.0
        this.btn_text = '下一步'
      } else {
        this.btn_text = '提交资质审核'
      }
      let res = await projectDoctor()
      if ((!res.hasOwnProperty('informed_consent') || !res.informed_consent) && res.sign_type == 0) {
        this.skipSign = true
        // console.log('this.skipSign', this.skipSign)
      } else if (res.hasOwnProperty('informed_consent') && res.informed_consent && res.sign_type == 0) {
        this.skipSign = true
      }
      let { items } = await getSubProjectAgreement()
      this.is_bankcard_pay = items[0].bankcard_pay ? true : false
      if (this.is_bankcard_pay) this.btn_text = '下一步'
      this.pdfh5 = new Pdfh5('#demo', {
        pdfurl: parseUrl(items[0].informed_consent, this.baseFileUrl)
      })
      //监听完成事件
      this.pdfh5.on('complete', function (status, msg, time) {
        console.log('状态：' + status + '，信息：' + msg + '，耗时：' + time + '毫秒，总页数：' + this.totalNum)
      })
      this.checkDisabled = false
    },
    next() {
      if (this.is_bankcard_pay) {
        this.toBankcardPay()
        return
      }
      console.log('判断项目：', this.basicInformation)
      this.short_name = this.basicInformation.projects[0].short_name
      console.log('short_name:', this.short_name)
      if (this.skipSign) {
        this.btnDisabled = true
        const self = this
        let dialog_msg = '提交审核后不可修改，是否确认提交审核？'
        if (this.$route.query.follow_submit) {
          dialog_msg = '提交后不可修改，是否确认提交？'
        }
        // 如果是诺亚方舟2期、安欣保（安心首选）、瑞意人生、消化肿瘤2.0项目则跳转到签署劳务协议页面
        if (this.basicInformation?.subprojects[0]?.bind_type == 1) {
          // if (["nyfzeq", "axb", "ryrs", "xhzl", "sshy", "axbplus"].includes(this.short_name)) {
          this.$router.replace({
            path: '/service_agreement',
            query: {
              ...this.$route.query
            }
          })
        } else {
          Dialog.confirm({
            message: dialog_msg,
            title: '确定提交',
            showCancelButton: true,
            cancelButtonText: '我再想想',
            confirmButtonColor: '#4B99FF',
            confirmButtonText: '确定提交'
          })
            .then(() => {
              Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true
              })
              self.btnDisabled = false
              StepFour({
                project_doctor_id: this.project_doctor_id,
                idcard_photos: this.idcardList
              })
                .then(res => {
                  sessionStorage.removeItem('informed_consent_sign')
                  self.btnDisabled = false
                  Toast.clear()
                  self.$router.replace({
                    path: '/sub_project'
                  })
                })
                .catch(err => {
                  self.btnDisabled = false
                  Toast.clear()
                  Toast(err.response.data.message)
                })
            })
            .catch(() => {
              self.btnDisabled = false
            })
        }
      } else {
        if (this.short_name == 'bxzleq') {
          //靶向治疗二期跳转阅读知情同意书后跳转服务协议再签名
          this.$router.replace({
            path: '/service_agreement',
            query: {
              ...this.$route.query
            }
          })
        } else {
          this.$router.replace({
            path: '/add_sign',
            query: {
              project_doctor_id: this.project_doctor_id
            }
          })
        }
      }
    },
    async back() {
      const data = await getDoctorForm({
        params: {
          doctor_id: this.userId,
          subproject_id: this.sub_id
        }
      })
      this.formList = data
      if (this.formList[3].form.length > 0) {
        this.$router.replace({
          path: '/add_idcard',
          query: {
            project_doctor_id: this.project_doctor_id,
            dire: 'back'
          }
        })
      } else {
        this.$router.replace({
          path: '/add_idsn',
          query: {
            project_doctor_id: this.project_doctor_id,
            dire: 'back'
          }
        })
      }
    },
    toBankcardPay() {
      this.$router.replace({
        name: 'EacAddAgreement',
        query: {
          project_doctor_id: this.project_doctor_id,
          dire: 'front'
        }
      })
      return
    }
  }
}
</script>

<style lang="scss" scoped>
.introduce {
  height: 100%;
  width: 100%;

  .text {
    padding: 10px;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    margin-bottom: 70px;
  }
  .agree-box {
    line-height: 20px;
    padding: 10px;
    text-align: center !important;
    vertical-align: middle;
    font-size: 14px;
    .van-checkbox {
      justify-content: center;
    }
  }
}
</style>
